const rules = {
  "full-access": { action: "full-access" },

  "view-evaluation": { action: "view-evaluation" },
  "view-dashboard": { action: "view-dashboard" },
  "view-transfer": { action: "view-transfer" },
  "view-marketplace": { action: "view-marketplace" },
  "view-consorcio": { action: "view-consorcio" },
  "view-my-proposals": { action: "view-my-proposals" },
  "view-customers": { action: "view-customers" },
  "view-reports": { action: "view-reports" },
  "view-groups": { action: "view-groups" },
  "view-system": { action: "view-system" },
  "view-notifications": { action: "view-notifications" },
  "view-settings": { action: "view-settings" },
  "view-concessionaires": { action: "view-concessionaires" },
  "view-users": { action: "view-users" },
  "view-invitation": { action: "view-invitation" },
  "view-teams": { action: "view-teams" },
  "view-vehicles": { action: "view-vehicles" },
  "view-external-link": { action: "view-external-link" },
  "view-meu-perfil": { action: "view-meu-perfil" },
  "view-notification-types": { action: "view-notification-types" },
  "view-consulta-cautelar-page": { action: "view-consulta-cautelar-page" },
  "view-consulta-cautelar": { action: "view-consulta-cautelar" },
  "view-limitar-lances" : { action: "view-limitar-lances" },
  "view-tabela-fipe": { action: "view-tabela-fipe" },
  "view-store-repasse": { action : "view-store-repasse"},

  "create-evaluation": { action: "create-evaluation" },
  "create-concessionaires": { action: "create-concessionaires" },
  "create-users": { action: "create-users" },
  "create-invitation": { action: "create-invitation" },
  "create-teams": { action: "create-teams" },
  "create-vehicles": { action: "create-vehicles" },
  "create-external-link": { action: "create-external-link" },
  "create-limit-bids" : { action: "create-limit-bids" },

  "edit-evaluation": { action: "edit-evaluation" },
  "edit-concessionaires": { action: "edit-concessionaires" },
  "edit-users": { action: "edit-users" },
  "edit-invitation": { action: "edit-invitation" },
  "edit-teams": { action: "edit-teams" },
  "edit-vehicles": { action: "edit-vehicles" },
  "edit-external-link": { action: "edit-external-link" },

  "edit-appraisal-status-recebido": {
    action: "edit-appraisal-status-recebido",
  },
  "edit-appraisal-appraiser": { action: "edit-appraisal-appraiser" },
  "edit-appraisal-receive-announce": {
    action: "edit-appraisal-receive-announce",
  },
  "edit-repasse-receive-announce": { action: "edit-repasse-receive-announce" },
  "editar-campos-opcionais": { action: "editar-campos-opcionais" },
  "edit-limit-bids" : { action: "edit-limit-bids" },

  "delete-evaluation": { action: "delete-evaluation" },
  "delete-concessionaires": { action: "delete-concessionaires" },
  "delete-users": { action: "delete-users" },
  "delete-invitation": { action: "delete-invitation" },
  "delete-teams": { action: "delete-teams" },
  "delete-vehicles": { action: "delete-vehicles" },
  "delete-appraisal": { action: "delete-appraisal" },
  "delete-external-link": { action: "delete-external-link" },
  "delete-limit-bids" : { action: "delete-limit-bids" },

  "enviar-email-solicitacao-cautelar": {
    action: "enviar-email-solicitacao-cautelar",
  },

  "can-accept-refuse-transfer": { action: "can-accept-refuse-transfer" },
  "can-request-consulta-cautelar": { action: "can-request-consulta-cautelar" },
  "can-upload-consulta-cautelar": { action: "can-upload-consulta-cautelar" },
  "can-download-consulta-cautelar": {
    action: "can-download-consulta-cautelar",
  },
};

export default {
  Sudo: [rules["full-access"]],
  Administrador: [
    rules["view-dashboard"],
    rules["view-users"],
    rules["view-evaluation"],
    rules["view-teams"],
    rules["view-reports"],
    rules["view-settings"],
    rules["view-external-link"],
    rules["view-my-proposals"],
    rules["view-meu-perfil"],

    rules["can-accept-refuse-transfer"],

    rules["create-users"],
    rules["create-evaluation"],
    rules["create-teams"],
    rules["create-external-link"],

    rules["edit-users"],
    rules["edit-evaluation"],
    rules["edit-teams"],
    rules["edit-appraisal-appraiser"],
    rules["edit-external-link"],
    rules["edit-appraisal-receive-announce"],
    rules["edit-repasse-receive-announce"],

    rules["delete-users"],
    rules["delete-evaluation"],
    rules["delete-teams"],
    rules["delete-appraisal"],

    rules["view-marketplace"],
    rules["view-concessionaires"],
    rules["view-invitation"],
    rules["view-customers"],
    rules["view-transfer"],
    rules["enviar-email-solicitacao-cautelar"],
    rules["editar-campos-opcionais"],

    rules["view-consulta-cautelar"],
    rules["can-request-consulta-cautelar"],
    rules["can-download-consulta-cautelar"],
  ],
  Gerente: [
    rules["view-dashboard"],
    rules["view-evaluation"],
    rules["view-teams"],
    rules["view-reports"],
    rules["view-settings"],
    rules["view-users"],
    rules["view-external-link"],
    rules["view-my-proposals"],
    rules["view-meu-perfil"],

    rules["can-accept-refuse-transfer"],

    rules["edit-users"],
    rules["create-evaluation"],
    rules["create-teams"],
    rules["create-external-link"],

    rules["edit-evaluation"],
    rules["edit-teams"],
    rules["edit-appraisal-appraiser"],
    rules["edit-external-link"],
    rules["edit-appraisal-receive-announce"],
    rules["edit-repasse-receive-announce"],

    rules["delete-evaluation"],
    rules["delete-teams"],
    rules["view-marketplace"],
    rules["view-concessionaires"],
    rules["view-invitation"],
    rules["view-customers"],
    rules["view-transfer"],
    rules["enviar-email-solicitacao-cautelar"],
    rules["editar-campos-opcionais"],

    rules["view-consulta-cautelar"],
    rules["can-request-consulta-cautelar"],
    rules["can-download-consulta-cautelar"],
  ],
  Avaliador: [
    rules["view-dashboard"],
    rules["view-evaluation"],
    rules["view-meu-perfil"],
    rules["create-evaluation"],
    rules["edit-evaluation"],
    rules["edit-appraisal-appraiser"],
    rules["edit-appraisal-receive-announce"],
  ],
  Lojista: [
    rules["view-dashboard"],
    rules["view-marketplace"],
    rules["view-transfer"],
    rules["view-consorcio"],
    rules["view-meu-perfil"],
    rules["view-my-proposals"],
  ],
  Vendedor: [
    rules["view-dashboard"],
    rules["view-evaluation"],
    rules["view-meu-perfil"],
    rules["view-tabela-fipe"],
    rules["create-evaluation"],
    rules["edit-evaluation"],
    rules["edit-appraisal-receive-announce"],
  ],
  Supervisor: [
    rules["view-dashboard"],
    rules["view-reports"],
    rules["view-teams"],
    rules["view-settings"],
    rules["view-evaluation"],
    rules["view-customers"],
    rules["view-meu-perfil"],
    // rules['view-transfer'],

    rules["edit-teams"],
    // rules['edit-appraisal-receive-announce'],
    rules["edit-appraisal-appraiser"],
  ],
};
