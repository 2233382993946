import { Service } from "../../config/service";

class ConsultaCautelarService extends Service {

  constructor() {
    super("consulta-cautelar")
  }

}

export default ConsultaCautelarService
