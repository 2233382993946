import { http, http84 } from '../axios/index.js'

export default class ServiceModel {

  constructor () {
    this.http84 = http84
    this.http = http
    this.http.useInterceptors()
    this.http84.useInterceptors()

    this._resource = {}
    this._routes = {}
    this._relationship = null
    this._id = null
    this._params = {}
    this._error = null
  }

  getHttp() {
    return this.http
  }

  getHttp84() {
    return this.http84
  }

  getResource () {
    return this._resource
  }

  setResource (resource) {
    this._resource = {
      root: resource,
      list: resource,
      get: resource,
      insert: resource,
      update: resource,
      delete: resource
    }
    return this
  }

  getRoutes () {
    return this._routes
  }

  addRoute (key, value) {
    this._routes[key] = value
    return this
  }

  getRelationship () {
    return this._relationship
  }

  setRelationship (relationship) {
    this._relationship = relationship
    return this
  }

  getId () {
    return this._id
  }

  setId (id) {
    this._id = id
  }

  setPage (page) {
    this._params.page = (page && page > 0) ? page : null
    if (this._params.page) this.setLimit(true)
    return this
  }

  setLimit (enable) {
    this._params.limit = enable ? (process.env.VUE_APP_ITEMS_PER_PAGE || 5) : null
    return this
  }

  setSearch (search) {
    this._params.search = search
    return this
  }

  setSearchType (searchType) {
    this._params.search_type = searchType
    return this
  }

  setAlias (alias) {
    this._params.alias = alias
    return this
  }

  setParam (key, value) {
    this._params[key] = value
    return this
  }

  getParams () {
    return this._params
  }

  setError (error) {
    if (!error) {
      this._error = {
        message: 'Unspecified error'
      }
      return this
    }

    if (error.response) {
      if (typeof error.response.data === 'string') {
        this._error = {
          message: error.response.data,
          status: error.response.status
        }
        return this
      }

      if (typeof error.response.data === 'object') {
        this._error = {
          id: error.response.data.id,
          message: error.response.data.message || error.response.data.mensagem,
          status: error.response.status
        }
        return this
      }

      this._error = {
        message: error.response.statusText,
        status: error.response.status
      }
      return this
    }

    if(error.request) {
      this._error = {
        message: error.request.statusText || 'An unexpected error occurred, check the settings',
        status: error.request.status
      }
      return this
    }

    this._error = {
      message: 'Unexpected error'
    }
    return this
  }

  getError () {
    return this._error
  }

}
